.fashion-main{
background: rgb(255,255,255);
background: linear-gradient(356deg, rgba(255,255,255,0.12377450980392157) 37%, rgba(251,168,133,0.11817226890756305) 100%);
}

.fashion-section-1-text{
    /* background-color: #FEEFE8; */
    padding: 2em;
}

.fashion-section-1-text h1{
   color: #FBA885;
   text-align: justify;
   margin-top: 1em;
}
.fashion-section-1-text p{
  font-size: 1.1em;
  text-align: justify;
}

.fashion-section-2{
    margin-top: -7em;
}
.fashion-section-2 h3{
   color: #FBA885;
}

.fashion-section-2-bg {
    background-color: #ffffff54;

}

.fashion-section-3 h1{
   color: #FBA885;
   text-align: center;
   margin-top: 1em;
}
.fashion-section-3 p{

   text-align: justify;
}

.fashion-section-4 h1{
   color: #FBA885;
   text-align: center;
   margin-top: 1em;
}
.fashion-section-4 p{
   text-align: justify;

}

.fashion-section-5 h1{
   color: #FBA885;
   text-align: center;
   margin-top: 1em;

}

.fashion-section-5{
   text-align: justify;
}
.fashion-section-5 button{
background-color: #E4F0FD;
 color: #FBA885;
   text-align: center;
   border: 0px;
   display: block;
   margin: auto;
   outline: 0px;
   margin-top: 1em;
   padding: 0.5em 1em;
   font-weight: 600;

}

.catalog-img{
   width: 80%;
   padding: 2em;
   display: block;
   margin: auto;
}

@media screen and (max-width: 700px) {
    .fashion-main{
background: rgb(255,255,255);
background: linear-gradient(356deg, rgba(255,255,255,0.12377450980392157) 37%, rgba(251,168,133,0.11817226890756305) 100%);
}

.fashion-section-1-text{
    /* background-color: #FEEFE8; */
    padding: 1em;
}

.fashion-section-1-text h1{
   color: #FBA885;
   text-align: center;
   margin-top: 1em;
}
.fashion-section-1-text p{
  font-size: 1.1em;
}

.fashion-section-2{
    margin-top: 0em;
}
.fashion-section-2 h3{
   color: #FBA885;
}

.fashion-section-2-bg {
    background-color: #ffffff54;

}

.fashion-section-3 h1{
   color: #FBA885;
   text-align: center;
   margin-top: 1em;
}
.fashion-section-3 p{
   text-align: justify;

}

.fashion-section-4 h1{
   color: #FBA885;
   text-align: center;
   margin-top: 1em;
}
.fashion-section-4 p{
   text-align: justify;

}

.fashion-section-5 h1{
   color: #FBA885;
   text-align: center;
   margin-top: 1em;

}


.fashion-section-5 button{
background-color: #E4F0FD;
 color: #FBA885;
   text-align: center;
   border: 0px;
   display: block;
   margin: auto;
   outline: 0px;
   margin-top: 1em;
   padding: 0.5em 1em;
   font-weight: 600;

}

.catalog-img{
   width: 100%;
   padding: 0em;
   display: block;
   margin: auto;
}

}