.about{
    background: rgb(255,255,255);
	background:	linear-gradient(34deg, rgba(255,255,255,1) 38%, rgba(251,168,133,0.3) 100%);
}

.about-main-img{
    width: 100%;
    display: block;
    margin: auto;
}


.about-section-1 h2{
    font-size: 2em ;
    font-weight: 700;
    color: #FBA885;
}

.about-section-1 {
position: absolute;
text-align: justify;
width: 50%;
left: 0%;
top: 0%;
padding: 2em;
padding-top: 0%;
}

.about-section-1 p{
   width: 100%;
   font-size: 0.9em;
   
}

.form-section{
    margin-top: 6em;

}

.form-section img{
    width: 75%;

}

.careers  p{
    width: 40%;
    font-size: 1.1em;
}

.arrow-img{
    position:absolute;
    opacity:40%;
    bottom: 0%;
    width: 50%;
    z-index: 0;
}





@media screen and (max-width: 700px) {
    .about-section-1 {
position:relative;

width: 100%;
left: 0%;
top: 0%;
padding: 0.7em;
padding-top: 0%;
}
.about-section-1 p{
   width: 100%;
}

.form-section{
    margin-top: 3em;
}
.form-section img{
    width: 100%;
}
.careers  p{
    width: 100%;
    font-size: 1.1em;
}

.arrow-img{
    position:absolute;
    opacity:40%;
    bottom: 5%;
    width: 80%;
    z-index: 0;
}

}