.services-section-1 h1{
    color: #FBA885;
	font-weight: 700;
	font-size: 2em;
	z-index:  5,
}

.services-section-1 p{
    font-size: 1.1em;
    text-align: justify;
}

.services-section-2 h4{ 

    font-size: 1.5em;
}

.services-section-3{
    margin-top: 6em;
}

.services-section-3 h1{
    color: #FBA885;
    font-weight: 700;
    font-size: 2em;
    top: 10%;
    left: 40%;
    z-index: 1;
}

.services-section-3 p{
    text-align: justify;
}

.section-section-4 {
    margin-top: 6em;
}

.section-section-4 h1{
    color: #FBA885;
    font-weight: 700;
    font-size: 2em;
   
    z-index: 1;
    text-align:left;
}


.section-4-p {
    width: 80%;
    text-align: justify;
}
.services-section-5{
    margin-top: 6em;
}

.services-section-5 h1{
    color: #FBA885;
    font-weight: 700;
    font-size: 2em;
   
    z-index: 1;
    text-align:left;
}
.services-section-5 p{
    text-align: justify;
}


.services-section-6{
    margin-top: 3em;
}

.services-section-6 h1{
    color: #FBA885;
    font-weight: 700;
    font-size: 2em;
   
    z-index: 1;
    text-align:left;
}
.services-section-6 p{
    text-align: justify;
    width: 80%;
    display: block;

    margin: auto;
}


.services-section-7{
    margin-top: 4em;
}


.services-section-7 h1{
    color: #FBA885;
    font-weight: 700;
    font-size: 2em;
   
    z-index: 1;
    text-align:left;
}

@media screen and (max-width: 700px) {
    .services-section-1 h1{
    color: #FBA885;
	font-weight: 700;
	font-size: 2em;
	z-index:  5,
}
.services-section-1 p{
    font-size: 0.99em;
}
.services-section-2 h4{ 

    font-size: 1em;
}

.services-section-3{
    margin-top: 3em;
}

.services-section-3 h1{
    color: #FBA885;
    font-weight: 700;
    font-size: 1.2em;
    top: 0%;
    left: 52%;
    z-index: 1;
}

.section-section-4 {
    margin-top: 2em;
}

.section-section-4  h1{
    color: #FBA885;
    font-weight: 700;
    font-size: 1.9em ;
    position: relative;
    text-align: center;
    z-index: 1;
}
.section-section-4 {
    width: 100%;
    text-align: justify;
}

.services-section-5{
    margin-top: 1em;
}
.services-section-5 h1{
    color: #FBA885;
    font-weight: 700;
    font-size: 1.6em;
    position: relative;
    z-index: 1;
    margin-bottom: 1em;
    margin-top: 1em;
}


.services-section-6{
    margin-top: 2em;
}
.services-section-6 h1{
    color: #FBA885;
    font-weight: 700;
    font-size: 1.6em;
   
    z-index: 1;
    text-align:left;
}
.services-section-6 p{
    text-align: justify;
    width: 100%;
}



.services-section-7{
    margin-top: 2em;
}


.services-section-7 h1{
    color: #FBA885;
    font-weight: 700;
    font-size: 1.6em;
   
    z-index: 1;
    text-align:left;
}
.services-section-7 p{
    text-align: justify;
}

}