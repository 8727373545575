.home-section-1-bg-image{
    position: absolute;
    top: -18%;
    z-index: 1;
    opacity: 90%;
    width: 100%;
  
}

/* .home-section-1{
    background-image: url('https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/home+page+illustrations/bg+section1.png');


   background-size: cover;
    background-repeat: no-repeat; 
} */

.section-1-banner{
    width: 100%;
    display: block;
    margin: auto;
   
}
.section-1-text{
    z-index: 2;
}
.section-1-text h2{
    font-size: 3em;
    font-weight: 900;
    color: #fff;
}

.view-demo{
    width: 30%;

}
.section-1-text p{
    font-size: 1.1em;
    width: 80%;
    text-align: justify;
    color: #fff;
}

/* ......................... */

.home-section-2 h2{
    font-size: 3em;
    font-weight: 700;
    color:#f8aeaf;
    text-shadow: 2px 4px 6px #8FBFE1;
    text-align: center;
}

.home-section-2-desc p{
font-size: 0.99em;
text-align: justify;
}

.hover-info-parent:hover .hover-info{
    display: block;
  
}

.hover-info{
    display: none;
    position: absolute;
    padding:2em 2em;
    bottom: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.495);
    z-index: 4;
}

.section-2-blocks img{
    width: 50%;
}


.section-2-blocks p{
    font-size: 0.8em;
}

.home-section-2-img img{
    width: 80%;
}

/* ................................ */

.heading-outer .heading-line{
    background: #FBA885;
    height: 2px;
    width: 95%;
    z-index: 1;
    top: 50%;
    left: 50%;
    translate: -50% 50%;
    
}
.heading-outer .heading-line-left{
    background: #FBA885;
    height: 2px;
    width: 95%;
    z-index: 1;
    top: 40%;
    left: 50%;
    translate: -50% 50%;
    
}

.heading-outer .heading-line-h1 h1{
display: inline-block;
position: relative;
color: #FBA885;
font-weight: 700;
z-index: 5;
font-size: 2em;
background-color: #fff;

}

.heading-outer .heading-line-h1-left h1{
display: inline-block;
position: relative;
color: #FBA885;
font-weight: 700;
z-index: 5;
font-size: 2em;
background-color: #fff;


}

/* ........................ */

.section-4 img{
    width: 75%;
    margin:auto;
    display: block;
}

.section-4-text-1 {
    position: absolute;
    left: 0%;
    top: 50%;
    translate:0% -50%;
    text-align: justify;
}

.section-4-text-2 {
    position: absolute;
    right: 0%;
    top: 70%;
    translate:0% -70%;
       text-align: justify;
}



/* ........................ */

.section-5{
background: rgb(246,198,193);
background:linear-gradient(90deg, rgba(246,198,193,1) 0%, rgba(248,207,198,1) 44%, rgba(246,246,248,1) 91%);

}

.section-6{
    width: 50%;
}



.card-inner{
    position: absolute;
    bottom: 0%;
    width: 100%;
    padding: 2%;
    z-index: 2;
background: rgb(0,0,0);
background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.21060924369747902) 49%, rgba(0,0,0,0.5159313725490196) 100%);
}

.card-inner h3{
    font-weight: 700;
    color: #fff;
    

}