@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;500;700&display=swap');

body{
  padding:0px;
  margin:0px;
font-family: 'Josefin Sans', sans-serif;
	/* background: linear-gradient(270deg, #A8B8D8 -17.36%, rgba(98, 117, 148, 0.00) 111.57%); */
scroll-behavior: smooth;
}


footer{
  padding: 2em;
  background: rgb(214,234,252);
background: linear-gradient(90deg, rgba(214,234,252,1) 0%, rgba(239,215,218,1) 43%, rgba(248,192,178,1) 71%, rgba(248,215,208,1) 89%);
}

footer img{
  width: 130px;
}
.footer-links p{
  font-size: 0.9em;
}

.nav-active-link{
  color: #E97255;
}


@media screen and (max-width: 700px) {
  
footer{
  padding: 2em 0.5em;
  background: rgb(214,234,252);
background: linear-gradient(90deg, rgba(214,234,252,1) 0%, rgba(239,215,218,1) 43%, rgba(248,192,178,1) 71%, rgba(248,215,208,1) 89%);
}

.footer-links{
  margin-top: 2em;
}

footer img{
  width: 130px;
}
.footer-links p{
  font-size: 0.9em;
}
}