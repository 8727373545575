
@media screen and (max-width: 700px) {

    .home-section-1-bg-image{
        display: none;
    top: -18%;
    z-index: 1;
    opacity: 90%;
    background-color: url('https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/home+page+illustrations/bg+section1.png');
}

.mobile-overlay{
    position: absolute;
    height: 100%;
    width: 100%;
    /* background-color: rgb(0, 0, 0,0.5); */
      opacity: 90%;
   

   background-image: url('https://s3.ap-south-1.amazonaws.com/website-2.0-ingnious/home+page+illustrations/header+for+home+page+(1).png');
}

.mob-section-1-text {
    position: absolute;
    top: 45%;
    width: 100%;
    
}
.mob-section-1-text  h2{
    font-size: 2em;
    font-weight: 700;
    color: #fff;
}
.mob-section-1-text  p{
  text-align: center;
  
    color: #fff;
}

.mob-section-1-btn{
    position: absolute;
    bottom:5%;
   left: 50%;
   translate: -50% -0%;
    font-size: 1em;
    font-weight: 700;
    color: #fff;
}

.section-1-banner{
    width: 100%;
    display: block;
    
    margin-right:auto ;
    
}
.section-1-text{
    z-index: 2;
    position: absolute;
}
.section-1-text h2{
    font-size: 3.5em;
    font-weight: 900;
    color: #fff;
}

.section-1-text p{
    font-size: 1.1em;
    width: 60%;
    color: #fff;
}

/* ......................... */

.home-section-2 h2{
    font-size: 3em;
    font-weight: 700;
    color:#f8aeaf;
   
    text-align: center;
    text-shadow: 2px 4px 6px #8FBFE1;
}

.home-section-2-desc p{
font-size: 0.99em;
}

.section-2-blocks img{
    width: 50%;
}


.section-2-blocks p{
    font-size: 0.8em;
}

.home-section-2-img img{
    width: 80%;
    
}

/* ................................ */

.heading-outer .heading-line{
    background: #FBA885;
    height: 2px;
    width: 95%;
    z-index: 1;
    top: 50%;
    left: 50%;
    translate: -50% 50%;
    
}
.heading-outer .heading-line-left{
    background: #FBA885;
    height: 2px;
    width: 95%;
    z-index: 1;
    top: 40%;
    left: 50%;
    translate: -50% 50%;
    
}

.heading-outer .heading-line-h1 h1{
display: inline-block;
position: relative;
color: #FBA885;
font-weight: 700;
z-index: 5;
font-size: 2em;
background-color: #fff;

}

.heading-outer .heading-line-h1-left h1{
display: inline-block;
position: relative;
color: #FBA885;
font-weight: 700;
z-index: 5;
font-size: 2em;
background-color: #fff;


}

/* ........................ */

.section-4 img{
    width: 75%;
    margin:auto;
    display: block;
}

.section-4-text-1 {
    position: absolute;
    left: 0%;
    top: 50%;
    translate:0% -50%;
}

.section-4-text-2 {
    position: absolute;
    right: 0%;
    top: 70%;
    translate:0% -70%;
}



/* ........................ */

.section-5{
background: rgb(246,198,193);
background:linear-gradient(90deg, rgba(246,198,193,1) 0%, rgba(248,207,198,1) 44%, rgba(246,246,248,1) 91%);

}

.section-6{
    width: 90%;
    text-align: justify;
}

}